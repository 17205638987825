import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HelmetEx from '../components/helmet-ex';
import Share from '../components/share';
import Footer from '../components/footer';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import BlogAuthor from '../components/blog-author';
import Banner from '../components/banner';
import HtmlRenderer from '../components/html-renderer';

import StringUtils from '../utils/string-utils';
import { BlogUtils } from '../utils/blog-utils';
import { ROUTES } from '../resources/references';
import LocalizationService from '../services/localization-service';
import config from '../config';

const IMAGES_PROTOCOL = 'https:';
class BlogPost extends Component {
  localizationService = new LocalizationService(this.props.data.contentfulResourceSet.resources);

  render() {
    const { site, contentfulBlogPost = {}, allContentfulBlogPost } = this.props.data;
    const article = contentfulBlogPost;
    const { twitterHandle } = site.siteMetadata;
    const metadata = {
      description: article.metaDescription,
      image: `${IMAGES_PROTOCOL}${article?.coverImage?.gatsbyImageData?.images?.sources?.[0]?.srcSet}`,
      keywords: article.keywords,
      path: `${ROUTES.BLOG}/${article.path}`,
      title: article.title,
    };

    return <Layout>
      <div className="blog-post-page">
        <HelmetEx>
          <div metadata="title">{metadata.title}</div>
          <div metadata="keywords">{metadata.keywords}</div>
          <div metadata="path">{metadata.path}</div>
          <div metadata="canonicalurl">{metadata.path}</div>
          <div metadata="description">{metadata.description}</div>
          <div metadata="image">{metadata.image}</div>
          <div metadata="og:title">{metadata.title}</div>
          <div metadata="og:description">{metadata.description}</div>
          <div metadata="og:url">{metadata.path}</div>
        </HelmetEx>
        <Banner title={`<i class="${BlogUtils.categoryIcon(article.category)} me-4" title="${article.category?.name}"></i>${article.title}`} />
        <section className="py-4">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <GatsbyImage image={article?.coverImage?.gatsbyImageData} className="rounded" alt={article.title} />
                <div className="mt-4 mb-4">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-8 col-sm-8 col-md-4 pr-0 d-flex flex-row align-items-center text-secondary">
                      <i className="far fa-calendar-alt me-2"></i>
                      {article.date}
                    </div>
                    <div className="col-4 text-end">
                      <Share
                        url={`${process.env.GATSBY_PLWEB_WEB_ADDRESS}/blog/${article.path}`}
                        twitterHandle={twitterHandle}
                        tags={article.tags}
                        buttons={config.SocialMediaButtons}
                        horizontalAlignment="right"
                      />
                    </div>
                  </div>
                </div>
                <p>
                  <p>{article.abstract}</p>
                </p>
                <HtmlRenderer richContent={contentfulBlogPost.body} />
                <div className="col p-0 mt-2">
                  <Share
                    url={`${process.env.GATSBY_PLWEB_WEB_ADDRESS}/blog/${article.path}`}
                    twitterHandle={twitterHandle}
                    tags={article.tags}
                    buttons={config.SocialMediaButtons}
                    horizontalAlignment="left"
                  />
                </div>
              </div>
              <div className="mt-5 mb-5 mb-lg-0 mt-lg-0 col-11 col-sm-10 col-md-7 col-lg-4 px-4">
                <div className="sticky-top">
                  <BlogAuthor author={article?.author} />
                  {allContentfulBlogPost.edges.length > 0 && <div className="d-none d-lg-block mt-5">
                    <div className="title pb-4">
                      <h5 className="position-relative">{this.localizationService.getString('blogPost.popularPosts')}</h5>
                    </div>
                    <div className="p-0">
                      {allContentfulBlogPost.edges?.map(({ node }, index) => {
                        const popularArticle = node;
                        return <div key={index} className="mb-3">
                          <a href={`/blog/${popularArticle.path}`} className="col d-flex flex-row text-decoration-none">
                            <div className="row gx-1 w-100">
                              <div className="col-4">
                                <GatsbyImage
                                  image={popularArticle?.coverImage?.gatsbyImageData}
                                  className="rounded w-100"
                                  alt={popularArticle.title}
                                />
                              </div>
                              <div className="col-8 ps-4 title">
                                <h6>{StringUtils.clampHtmlString(popularArticle.title, 60)}</h6>
                                <p>{popularArticle.date}</p>
                              </div>
                            </div>
                          </a>
                        </div>;
                      })}
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Layout>;
  }
}

BlogPost.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export default BlogPost;

export const query = graphql`query BlogPostQuery($slug: String!) {
        site {
        siteMetadata {
        title
      twitterHandle
    }
  }
  contentfulResourceSet(name: {eq: "blogPost"}) {
    name
    resources {
        key
        value {
            value
        }
    }
}
  postPlaceholder: file(
    relativePath: {eq: "blog/placeholders/blog-post-placeholder.jpg"}
  ) {
        childImageSharp {
        gatsbyImageData(width: 132, height: 120, layout: CONSTRAINED)
    }
  }
  polymath: file(relativePath: {eq: "blog/authors/polymath.png"}) {
        childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: CONSTRAINED)
    }
  }
  visar: file(relativePath: {eq: "blog/authors/visar.png"}) {
        childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: CONSTRAINED)
    }
  }
  contentfulBlogPost(path: {eq: $slug}) {
        id
    title
    abstract
    metaDescription
    date(formatString: "MMMM DD, YYYY")
    path
    category {
      name
    }
    coverImage {
      gatsbyImageData(layout: FULL_WIDTH, breakpoints: 1140)
    }
    metadataCoverImage: coverImage {
      gatsbyImageData(layout: FULL_WIDTH, breakpoints: 1200)
    }
    body {
      raw
      references {
        ...on Node {
          __typename
          ... on ContentfulImageContentRow {
            title
            imageAlignment
            contentful_id
            description {
                raw
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
        }
          ...on ContentfulAsset {
            __typename
            id
            gatsbyImageData(layout: FULL_WIDTH, breakpoints: 1140)
            title
            contentful_id
          }
          ... on ContentfulBlogPost {
            id
            contentful_id
            path
            sys {
              contentType {
                sys {
                  linkType
                  type
                  id
                }
              }
              type
              revision
            }
            internal {
              type
            }
          }
        }
      }
    }
    author {
      name
      title
      email
      linkedInIdentifier
      twitterIdentifier
      facebookIdentifier
      profilePicture {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
  allContentfulBlogPost(
    sort: {order: DESC, fields: date}
    filter: {path: {ne: $slug}}
    limit: 3
  ) {
        edges {
        node {
        id
        title
        abstract
        metaDescription
        date(formatString: "MMMM DD, YYYY")
        path
        author {
        name
      }
        coverImage {
        gatsbyImageData(layout: FULL_WIDTH, breakpoints: 100)
        }
      }
    }
    pageInfo {
        pageCount
      itemCount
      perPage
      hasPreviousPage
      hasNextPage
      currentPage
    }
  }
}
`;
